import React from 'react'

export function IconCaretDown() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='caret-down'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 320 512'
      className='icon-caret-down'
    >
      <path
        fill='currentColor'
        d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'
      />
    </svg>
  )
}

export function IconCheck() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='check'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className='icon-check'
    >
      <path
        fill='currentColor'
        d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'
      />
    </svg>
  )
}

export function IconFolder() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-prefix='fas'
      data-icon='folder'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className='icon-folder'
    >
      <path
        fill='currentColor'
        d='M464 128H272l-64-64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48z'
      />
    </svg>
  )
}

export function IconFile() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='file'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      className='icon-file'
      viewBox='0 0 384 512'
    >
      <path
        fill='currentColor'
        d='M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z'
      />
    </svg>
  )
}

export function IconChevronLeft() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='chevron-left'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 320 512'
      className='icon-chevron-left'
    >
      <path
        fill='currentColor'
        d='M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z'
      />
    </svg>
  )
}

export function IconChevronRight() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='chevron-right'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 320 512'
      className='icon-chevron-right'
    >
      <path
        fill='currentColor'
        d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'
      />
    </svg>
  )
}

export function IconChevronDown() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='chevron-down'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 448 512'
      className='icon-chevron-down'
    >
      <path
        fill='currentColor'
        d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'
      />
    </svg>
  )
}

export function IconChevronUp() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='chevron-up'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 448 512'
      className='icon-chevron-up'
    >
      <path
        fill='currentColor'
        d='M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z'
      />
    </svg>
  )
}

export function IconGithub() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='github'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 496 512'
      className='icon-github'
    >
      <path
        fill='currentColor'
        d='M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z'
      />
    </svg>
  )
}

export function IconList() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='list'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className='icon-list'
    >
      <path
        fill='currentColor'
        d='M128 116V76c0-8.837 7.163-16 16-16h352c8.837 0 16 7.163 16 16v40c0 8.837-7.163 16-16 16H144c-8.837 0-16-7.163-16-16zm16 176h352c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H144c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h352c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H144c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zM16 144h64c8.837 0 16-7.163 16-16V64c0-8.837-7.163-16-16-16H16C7.163 48 0 55.163 0 64v64c0 8.837 7.163 16 16 16zm0 160h64c8.837 0 16-7.163 16-16v-64c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v64c0 8.837 7.163 16 16 16zm0 160h64c8.837 0 16-7.163 16-16v-64c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v64c0 8.837 7.163 16 16 16z'
      />
    </svg>
  )
}

export function IconPlus() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-prefix='fas'
      data-icon='plus'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 448 512'
      className='icon-plus'
    >
      <path
        fill='currentColor'
        d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z'
      />
    </svg>
  )
}

export function IconSearch() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='search'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className='icon-search'
    >
      <path
        fill='currentColor'
        d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'
      />
    </svg>
  )
}

export function IconTimes() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='time'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14.6 14.6'
      className='icon-time'
    >
      <title>close</title>
      <line className='st0' x1='1.5' y1='1.5' x2='13.1' y2='13.1' />
      <line className='st0' x1='13.1' y1='1.5' x2='1.5' y2='13.1' />
    </svg>
  )
}

export function IconTwitter() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='twitter'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className='icon-twitter'
    >
      <path
        fill='currentColor'
        d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'
      />
    </svg>
  )
}

export function IconNavigation() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='navigation'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 448 512'
      className='icon-navigation'
    >
      <path
        fill='currentColor'
        d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'
      />
    </svg>
  )
}

export function IconSun() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='sun'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className='icon-sun'
    >
      <path
        fill='currentColor'
        d='M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z'
      />
    </svg>
  )
}

export function IconMoon() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='moon'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className='icon-moon'
    >
      <path
        fill='currentColor'
        d='M283.211 512c78.962 0 151.079-35.925 198.857-94.792 7.068-8.708-.639-21.43-11.562-19.35-124.203 23.654-238.262-71.576-238.262-196.954 0-72.222 38.662-138.635 101.498-174.394 9.686-5.512 7.25-20.197-3.756-22.23A258.156 258.156 0 0 0 283.211 0c-141.309 0-256 114.511-256 256 0 141.309 114.511 256 256 256z'
      />
    </svg>
  )
}

export function IconRemove() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='remove'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className='icon-remove'
    >
      <g id='Layer_1_1_'>
        <path
          className='st0'
          d='M494,256c0,131.4-106.6,238-238,238S18,387.4,18,256S124.6,18,256,18S494,124.6,494,256z'
        />
      </g>
      <g id='Layer_2_1_'>
        <line className='st1' x1='114.4' y1='260' x2='397.5' y2='260' />
      </g>
    </svg>
  )
}

export function IconEnter() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='enter'
      role='img'
      className='icon-enter'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 128 128'
    >
      <g>
        <polygon
          points='112.5,23.3 112.5,75.8 37.5,75.8 37.5,56 0.5,83.3 37.5,110.7 37.5,90.8 112.5,90.8 112.5,90.8 
  		127.5,90.8 127.5,23.3 	'
        />
      </g>
    </svg>
  )
}

export function IconHistory() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      version='1.1'
      viewBox='0 0 20 21'
      width='20px'
      role='img'
      className='icon-history'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <g
          fill='currentColor'
          id='Core'
          opacity='0.9'
          transform='translate(-464.000000, -254.000000)'
        >
          <g transform='translate(464.000000, 254.500000)'>
            <path
              d='M10.5,0 C7,0 3.9,1.9 2.3,4.8 L0,2.5 L0,9 L6.5,9 L3.7,6.2 C5,3.7 7.5,2 10.5,2 C14.6,2 18,5.4 18,9.5 C18,13.6 14.6,17 10.5,17 C7.2,17 4.5,14.9 3.4,12 L1.3,12 C2.4,16 6.1,19 10.5,19 C15.8,19 20,14.7 20,9.5 C20,4.3 15.7,0 10.5,0 L10.5,0 Z M9,5 L9,10.1 L13.7,12.9 L14.5,11.6 L10.5,9.2 L10.5,5 L9,5 L9,5 Z'
              id='Shape'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export function IconFavorite() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      viewBox='0 0 512 512'
      role='img'
      className='icon-favorite'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title />
      <g data-name='1' id='_1'>
        <path
          fill='currentColor'
          d='M370.24,425.59a14.89,14.89,0,0,1-7-1.72L257,368,150.74,423.87A15,15,0,0,1,129,408.06l20.3-118.32-86-83.8a15,15,0,0,1,8.31-25.59l118.81-17.26L243.55,55.43a15,15,0,0,1,26.9,0l53.13,107.66,118.8,17.26a15,15,0,0,1,8.32,25.59l-86,83.8L385,408.06a15,15,0,0,1-14.78,17.53ZM106,205.67l69.85,68.09A15,15,0,0,1,180.17,287l-16.49,96.14L250,337.78a15,15,0,0,1,14,0l86.34,45.39L333.83,287a15,15,0,0,1,4.31-13.27L408,205.67l-96.53-14a15,15,0,0,1-11.29-8.2L257,96l-43.17,87.47a15,15,0,0,1-11.3,8.2Z'
        />
      </g>
    </svg>
  )
}

export function IconDelete() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      className='icon-edit'
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        d='M5.3,18.7c.2.2.4.3.7.3s.5-.1.7-.3l5.3-5.3,5.3,5.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1,0-1.4l-5.3-5.3,5.3-5.3c.4-.4.4-1,0-1.4s-1-.4-1.4,0l-5.3,5.3-5.3-5.3c-.4-.4-1-.4-1.4,0s-.4,1,0,1.4l5.3,5.3-5.3,5.3c-.4.4-.4,1,0,1.4Z'
      />
    </svg>
  )
}

export function IconEdit() {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      className='icon-edit'
      fill='none'
      stroke='currentColor'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        strokeWidth='2'
        strokeLinejoin='round'
        strokeLinecap='round'
        d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7'
      />
      <path
        strokeWidth='2'
        strokeLinejoin='round'
        strokeLinecap='round'
        d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z'
      />
    </svg>
  )
}
